<template>
  <component
    :is="icon"
    :class="iconClass"
    :style="{ height: addPxUnit(props.height), width: addPxUnit(props.width) }"
    class="fill-current" />
</template>

<script setup>
import { defineAsyncComponent, computed, watch } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  height: {
    type: [String, Number],
    default: ""
  },
  width: {
    type: [String, Number],
    default: ""
  }
});

const iconClass = computed(() => `papier-icon icon-${props.name}`);

const addPxUnit = computed(() => value => {
  const hasPxUnit = `${value}`.includes("px");
  return hasPxUnit ? value : `${value}px`;
});

let icon = defineAsyncComponent(
  () => import(`../../../assets/images/svg/${props.name}.svg`)
);

watch(
  () => props.name,
  () => {
    icon = defineAsyncComponent(
      () => import(`../../../assets/images/svg/${props.name}.svg`)
    );
  }
);
</script>
